@font-face {
  font-family: 'RobotoFlex-Regular';
  font-weight: 400;
  src: url('../../assets/fonts/roboto-flex-latin-variable-wghtOnly-normal.woff2')
    format('woff2');
}

@font-face {
  font-family: 'RobotoFlex-Medium';
  font-weight: 500;
  src: url('../../assets/fonts/roboto-flex-latin-variable-wghtOnly-normal.woff2')
    format('woff2');
}

@font-face {
  font-family: 'RobotoFlex-SemiBold';
  font-weight: 600;
  src: url('../../assets/fonts/roboto-flex-latin-variable-wghtOnly-normal.woff2')
    format('woff2');
}

@font-face {
  font-family: 'RobotoFlex-Bold';
  font-weight: 700;
  src: url('../../assets/fonts/roboto-flex-latin-variable-wghtOnly-normal.woff2')
    format('woff2');
}

$primary-font: 'RobotoFlex-Regular', sans-serif;
$primary-font-light: 'RobotoFlex-Medium', sans-serif;
$primary-font-demi: 'RobotoFlex-Medium', sans-serif;
$primary-font-demibold: 'RobotoFlex-SemiBold', sans-serif;
$primary-font-bold: 'RobotoFlex-Bold', sans-serif;
