@import './scss/variables/fonts.scss';
@import './scss/variables/color.scss';

html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: $primary-font;
  color: $dark-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
